<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo style="height: 4em;" />
        </b-link>

        <b-card class="bg-light-secondary mb-2 text-center">
          <small>
            A new password will be sent to your registered e-mail address.
          </small>
        </b-card>

        <!-- form -->
        <validation-observer ref="loginValidation" #default="{invalid}">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="userName"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    autofocus
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->

              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                @click="passwordReset"
                block
                :disabled="invalid"
              >
                Reset Password
              </b-button>

              <div
                class="mt-1 mb-1 d-flex align-items-center justify-content-center"
              >
                <a href="/login"> I have an account login </a>
              </div>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /passwordReset v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import router from "@/router";
import useJwt from "@/auth/jwt/useJwt";
import { mapState, mapActions } from "vuex";
import vSelect from "vue-select";
import homeStoreModule from "./homeStoreModule";
import { ref, onUnmounted, reactive } from "@vue/composition-api";

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    localStorage.removeItem("userData");
    localStorage.removeItem("userPerms");
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = "home";
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      status: "",
      password: "",
      userName: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
      users: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    validPerm: {
      get() {
        return this.$store.getters["validPermissions"];
      },
      set(newValue) {
        return this.$store.dispatch("setValidPermission", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["getTeamNames", "setUser", "setValidPermission"]),

    passwordReset() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          store
            .dispatch("home/passwordReset", { user: this.userName })
            .then((response) => {
              if (response.data.success) {
                // this.$swal({
                //   title: "Success",
                //   text: response.data.message,
                //   icon: "success",
                //   showCancelButton: false,
                //   confirmButtonText: "OK",
                // });
                this.successMessage(response.data.message);

                router.push({ path: "/login" });
                this.show = false;
              } else {
                this.$swal({
                  title: "User not found",
                  text: response.data.message,
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "OK",
                });

                this.show = false;
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Error",
                text: "Error occurred, please try again or contact support",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "OK",
              });

              this.show = false;
              this.$refs.loginValidation.setErrors(error.response.data.error);
            });
        }
      });
    },

    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
