import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    setUser(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('setUser', user)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('fetchUser', user)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    passwordReset(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('passwordReset', user)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    

  },
};
